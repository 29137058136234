.MainNav {
  display: none;
}

.MainNav__opener {
  order: 1;
  margin-left: 24px;
}

.MainNav_opened {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  border: solid 10px white;
  padding: 20px;
  overflow: scroll;
  border-radius: 16px;
  background-color: rgb(52, 101, 255);
}

.MainNav__closer {
  display: none;
  flex-shrink: 0;
}

.MainNav_opened .MainNav__closer {
  display: block;
  margin-left: auto;
}

.MainNav__list {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
}

.MainNav_opened .MainNav__list {
  display: block;
}

.MainNav_opened .MainNav__item {
  padding-top: 15px;
  padding-bottom: 15px;
}

.MainNav:not(.MainNav_opened) .MainNav__item {
  margin-left: 8px;
  margin-right: 8px;
}

.MainNav__link {
  font-size: 18px;
  line-height: 30px;
  text-decoration: none;
  color: black;
  outline: none;
}

.MainNav__link:focus-visible {
  outline: solid #ea0 2px;
}

.MainNav_opened .MainNav__link {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: white;
  outline: none;
}

.MainNav_opened .MainNav__link:focus-visible,
.MainNav_opened .MainNav__phoneLink:focus-visible {
  outline: solid yellow 2px;
}

.MainNav_opened .MainNav__link_sub {
  font-size: 16px;
  line-height: 26px;
}

.MainNav__phoneLink {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
}

.MainNav:not(.MainNav_opened) .MainNav__phoneLink {
  margin-left: 24px;
}

.MainNav__phoneLink:focus-visible {
  outline: solid #ea0 2px;
}

.MainNav_opened .MainNav__phoneLink {
  margin-top: 24px;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
  color: white;
  outline: none;
}

@media (min-width: 640px) {
  .MainNav {
    display: flex;
    flex-direction: row;
  }

  .MainNav_opened {
    flex-direction: column;
  }
}

@media (min-width: 1000px) {
  .MainNav__list {
    display: flex;
    flex-direction: row;
  }

  .MainNav__list_sub {
    display: none;
  }

  .MainNav__opener {
    display: none;
  }

  .MainNav__closer {
    display: none;
  }

  .MainNav__item {
    display: none;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      display: block;
    }
  }

  .MainNav__phoneLink:hover,
  .MainNav__link:hover {
    text-decoration: underline;
  }
}