@import '../../global/styles.scss';

.LinksList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: $spacingLg;
}

.LinksList__link {
  margin: 6px 0;
}