.Layout__html {
  height: 100%;
}

.Layout__body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  height: 100%;

  &_fixed {
    overflow: hidden;
  }
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Layout__main {
  flex-grow: 1;
  margin-top: 24px;
}
