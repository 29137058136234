.Button {
  display: inline-block;
  padding: 10px 15px;
  border: none;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: all 200ms ease;
}

.Button_shadow_always {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27), 0 12px 26px 0 rgba(0, 0, 0, 0.2);
}

.Button_shadow_thin {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.Button_shadow_hover:hover,
.Button_shadow_hover:focus-visible {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27), 0 12px 26px 0 rgba(0, 0, 0, 0.2);
}

.Button_skin_blue {
  color: white;
  background-color: rgb(72, 121, 255);
}

.Button_skin_blue:hover,
.Button_skin_blue:focus-visible {
  background-color: rgb(52, 101, 255);
}

.Button_skin_green {
  color: white;
  background-color: #10ad44;
}

.Button_skin_green:hover,
.Button_skin_green:focus-visible {
  background-color: #0ab844;
}

.Button_skin_white {
  color: rgb(72, 121, 255);
  background-color: white;
}

.Button_skin_white:hover,
.Button_skin_white:focus-visible {
  background-color: rgb(226, 234, 255);
}

.Button_skin_transparent {
  color: rgb(72, 121, 255);
  background-color: rgba(110, 148, 253, 0);
}

.Button_skin_transparent:hover,
.Button_skin_transparent:focus-visible {
  background-color: rgba(110, 148, 253, 0.2);
}

.Button_size_l {
  padding: 14px 18px;
  font-size: 20px;
  line-height: 24px;
}