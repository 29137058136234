@import '../../global/styles.scss';

.PageFooter {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: solid 1px lightgray;
  margin-top: $pageFooterTopMarginMobile;
}

.PageFooter__copyright {
  white-space: pre-line;
  font-size: 13px;
  color: gray;
}

.PageFooter__inner {
  display: flex;
  flex-direction: column;
}

.PageFooter__links,
.PageFooter__logo,
.PageFooter__phones,
.PageFooter__copyright {
  margin: 8px 16px;
}

.PageFooter__phones {
  display: flex;
  flex-direction: column;
}

.PageFooter__phoneLink {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  white-space: pre;
}

@media (min-width: 768px) {
  .PageFooter {
    margin-top: $pageFooterTopMarginDesktop;
  }

  .PageFooter__inner {
    flex-direction: row;
    justify-content: space-between
  }

  .PageFooter__copyright {
    // align-self: flex-end;
    text-align: right;
    max-width: 300px;
  }
}
