@import '../../global/styles.scss';

.PageHeader {
  z-index: 2;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
}

.PageHeader__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: $topPaneHeightDesktop;
}

.PageHeader__logo {
  margin-right: auto;
}