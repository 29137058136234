.PageWrap {
  max-width: 1440px;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
}

@media (min-width: 640px) {
  .PageWrap {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 960px) {
  .PageWrap {
    padding-left: 80px;
    padding-right: 80px;
  }
}
