@import '../../global/styles.scss';

.Link {
  display: inline;
  padding: 0;
  border: none;
  color: $colorLink;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: inherit;
  background-color: rgba(white, 0);
  cursor: pointer;

  &:visited {
    color: $colorVisited;
  }
}

.Link:hover {
  text-decoration: underline;
}

.Link:focus-visible {
  outline: solid $colorFocusOutline 2px;
}
